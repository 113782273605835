
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getUserPayStatisticsList, exportUserPayStatisticsList } from "@/api/request/recharge";

//组件
@Component({
  name: "RechargeStatistics",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    //页面数据
    page: 1, //分页
    page_size: 10, //每页行数

    //临时数据
    id_str: "", //记录ID
    user_id_str: "", //用户ID

    //接口数据
    id: 0, //记录 id
    user_id: 0, //用户 Id
    country: "", //国家
    reg_date: "", //注册日期
    nickname: "", //用户昵称
    channel_id: "", //渠道ID
    device_os: "", //设备系统
  };

  //设备参数
  private deviceOptions: any[] = [
    { value: "", label: "全部设备" },
    { value: "IOS", label: "IOS" },
    { value: "Android", label: "Android" },
  ];

  //国家参数
  private countryOptions: any[] = [
    { value: "", label: "全部国家" },
    { value: "AT", label: "奥地利" },
    { value: "GB", label: "英国" },
    { value: "DK", label: "丹麦" },
    { value: "SE", label: "瑞典" },
    { value: "NO", label: "挪威" },
    { value: "PL", label: "波兰" },
    { value: "DE", label: "德国" },
    { value: "PE", label: "秘鲁" },
    { value: "MX", label: "墨西哥" },
    { value: "CU", label: "古巴" },
    { value: "AR", label: "阿根廷" },
    { value: "BR", label: "巴西" },
    { value: "CL", label: "智利" },
    { value: "CO", label: "哥伦比亚" },
    { value: "VE", label: "委内瑞拉" },
    { value: "MY", label: "马来西亚" },
    { value: "AU", label: "澳大利亚" },
    { value: "ID", label: "印度尼西亚" },
    { value: "PH", label: "菲律宾" },
    { value: "NZ", label: "新西兰" },
    { value: "SG", label: "新加坡" },
    { value: "TH", label: "泰国" },
    { value: "JP", label: "日本" },
    { value: "KR", label: "韩国" },
    { value: "VN", label: "越南" },
    { value: "CN", label: "中国" },
    { value: "TR", label: "土耳其" },
    { value: "IN", label: "印度" },
    { value: "PK", label: "巴基斯坦" },
    { value: "AF", label: "阿富汗" },
    { value: "LK", label: "斯里兰卡" },
    { value: "MM", label: "缅甸" },
    { value: "IR", label: "伊朗" },
    { value: "EE", label: "爱沙尼亚" },
    { value: "MD", label: "摩尔多瓦" },
    { value: "AM", label: "亚美尼亚" },
    { value: "BY", label: "白俄罗斯" },
    { value: "AD", label: "安道尔" },
    { value: "MC", label: "摩纳哥" },
    { value: "SM", label: "圣马力诺" },
    { value: "UA", label: "乌克兰" },
    { value: "RS", label: "塞尔维亚" },
    { value: "ME", label: "黑山" },
    { value: "XK", label: "科索沃" },
    { value: "HR", label: "克罗地亚" },
    { value: "SI", label: "斯洛文尼亚" },
    { value: "BA", label: "波黑" },
    { value: "MK", label: "马其顿" },
    { value: "CZ", label: "捷克" },
    { value: "SK", label: "斯洛伐克" },
    { value: "LI", label: "列支敦士登" },
    { value: "BZ", label: "伯利兹" },
    { value: "GT", label: "危地马拉" },
    { value: "SV", label: "萨尔瓦多" },
    { value: "HN", label: "洪都拉斯" },
    { value: "NI", label: "尼加拉瓜" },
    { value: "CR", label: "哥斯达黎加" },
    { value: "PA", label: "巴拿马" },
    { value: "PM", label: "圣皮埃尔和密克隆群岛" },
    { value: "HT", label: "海地" },
    { value: "BO", label: "玻利维亚" },
    { value: "GY", label: "圭亚那" },
    { value: "EC", label: "厄瓜多尔" },
    { value: "GF", label: "法属圭亚那" },
    { value: "PY", label: "巴拉圭" },
    { value: "MQ", label: "马提尼克" },
    { value: "SR", label: "苏里南" },
    { value: "UY", label: "乌拉圭" },
    { value: "CW", label: "库拉索" },
    { value: "TL", label: "东帝汶" },
    { value: "NF", label: "诺福克岛" },
    { value: "BN", label: "文莱" },
    { value: "PW", label: "帕劳" },
    { value: "PG", label: "巴布亚新几内亚" },
    { value: "TO", label: "汤加" },
    { value: "SB", label: "所罗门群岛" },
    { value: "VU", label: "瓦努阿图" },
    { value: "FJ", label: "斐济" },
    { value: "MP", label: "北马里亚纳群岛" },
    { value: "WF", label: "瓦利斯和富图纳群岛" },
    { value: "CK", label: "库克群岛" },
    { value: "NU", label: "纽埃" },
    { value: "WS", label: "萨摩亚" },
    { value: "TK", label: "托克劳" },
    { value: "WF", label: "瓦利斯和富图纳群岛" },
    { value: "TV", label: "图瓦卢" },
    { value: "PF", label: "法属波利尼西亚" },
    { value: "TK", label: "托克劳" },
    { value: "FM", label: "密克罗尼西亚联邦" },
    { value: "MH", label: "马绍尔群岛" },
    { value: "KP", label: "朝鲜" },
    { value: "HK", label: "香港" },
    { value: "MO", label: "澳门" },
    { value: "KH", label: "柬埔寨" },
    { value: "LA", label: "老挝" },
    { value: "BD", label: "孟加拉国" },
    { value: "TW", label: "台湾" },
    { value: "MV", label: "马尔代夫" },
    { value: "LB", label: "黎巴嫩" },
    { value: "JO", label: "约旦" },
    { value: "SY", label: "叙利亚" },
    { value: "IQ", label: "伊拉克" },
    { value: "KW", label: "科威特" },
    { value: "SA", label: "沙特阿拉伯" },
    { value: "YE", label: "也门" },
    { value: "OM", label: "阿曼" },
    { value: "AE", label: "阿联酋" },
    { value: "IL", label: "以色列" },
    { value: "BH", label: "巴林" },
    { value: "QA", label: "卡塔尔" },
    { value: "BT", label: "不丹" },
    { value: "MN", label: "蒙古" },
    { value: "NP", label: "尼泊尔" },
    { value: "AZ", label: "阿塞拜疆" },
    { value: "GE", label: "格鲁吉亚" },
    { value: "KG", label: "吉尔吉斯斯坦" },
    { value: "UZ", label: "乌兹别克斯坦" },
    { value: "GG", label: "根治岛" },
    { value: "JE", label: "泽西岛" },
  ];

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.id = Number(this.listQuery.id_str);
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await getUserPayStatisticsList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理导出
  private async handleExport() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.id = Number(this.listQuery.id_str);
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await exportUserPayStatisticsList(this.listQuery);

    //保存数据
    saveAs(data, "用户充值数据统计列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }
}
